body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: env(safe-area-inset-top);
  margin-bottom: calc(4px + env(safe-area-inset-top));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loadingSpinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white; /* White background */
  /* background: #00000080; */
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}
